<template>
  <section class="section--padding terms">
    <v-container>
      <h1 class="text-center mb-2">УСЛОВИЯ ПРОДАЖИ</h1>
      <p class="text-center">Пожалуйста, внимательно прочитайте Условия продажи перед размещением заказа.</p>

      <h2 class="h4 my-8">1. Термины и их определения</h2>
      <p>В настоящей Оферте используются следующие термины:</p>
      <p>"Курьерская служба" - лица, оказывающие услуги по доставке Товаров от имени Продавца, или сотрудники
        Продавца.</p>
      <p>"Продавец" (также "Мы", "Нас", "Наш", "Наши") - Общество с ограниченной ответственностью</p>
      <p>"Сладкие подарки" (ИНН 7801279117, ОГРН 1157847149965), расположенное по адресу: 191144, Санкт-Петербург,
        Новгородская ул., дом 12, лит. Б, пом. 2-Н.</p>
      <p>"Покупатель" (также "Вы", "Вас", "Ваш" "Ваши") - любое дееспособное лицо, использующее сайт и (или)
        приобретающее Товары на сайте.</p>
      <p>"Сайт" -
        <router-link to="/">schastyesweet.com</router-link>
      </p>
      <p>"Телефон" - <a href="tel:+79217881818">+7(921)788-18-18</a>.</p>
      <p>"Электронная почта" - <a href="mailto:home@schastye.com">home@schastye.com</a></p>
      <p>"Товары" - продукция, предлагаемая к приобретению на Сайте. Товары могут отличаться от изображения на Сайте.
        Описание Товаров указано на Сайте.</p>
      <p>"Заказ" - намерение Покупателя приобрести Товары, выраженное способами, предусмотренными п. 5.1.</p>
      <p>"Условия доставки" - правила доставки Товаров, изложенные на Сайте в разделе "Доставка" или в ином подобном
        разделе.</p>
      <p>"Магазин" - торговая точка, поименованная в разделе "Где нас купить" Сайта.</p>

      <h2 class="h4 my-8">2. Общие положения</h2>
      <p>2.1. Продавец и Покупатель гарантируют, что обладают дееспособностью, а также всеми
        правами, необходимыми для заключения и исполнения договора розничной купли-продажи
        Товаров через сайт (дистанционным способом).</p>
      <p>2.2. До начала использования Сайта Вы должны ознакомиться с условиями Оферты, а также с
        размещенной на сайте Политикой обработки персональных данных.</p>
      <p>2.3. Мы вправе вносить изменения в настоящую Оферту. Вы должны самостоятельно
        отслеживать наличие изменений в Оферте и руководствоваться той редакцией Оферты, которая
        размещена на сайте в момент размещения вами Заказа.</p>
      <p>2.4. Оформляя Заказ Товаров, Вы подтверждаете ознакомление и полное согласие со всеми
        документами, указанными в п. 2.2.</p>
      <p>2.5. Договор розничной купли-продажи товаров дистанционным способом (далее - "Договор")
        считается заключенным с момента подтверждения Нами Вашего Заказа (п. 5.10).</p>
      <p>2.6. Настоящая Оферта в отношении Товаров действует в течение всего срока их размещения
        на Сайте.</p>

      <h2 class="h4 my-8">3. Предмет Договора</h2>
      <p>3.1. Продавец продает Товары по ценам, представленным на Сайте, а также доставляет Товар
        Курьерской службой, а Покупатель оплачивает и принимает Товары в соответствии с условиями
        настоящей Оферты.</p>
      <p>3.2. Право собственности и риск случайной гибели/повреждения Товаров переходит к Вам в
        момент получения Товаров.</p>

      <h2 class="h4 my-8">4. Уведомления</h2>
      <p>4.1. Мы будем уведомлять Вас о совершенном Заказе, его составе, этапах его обработки и о
        готовности Заказа путем направления сервисных сообщений на Ваш номер телефона. Такие
        сервисные сообщения не могут быть отклонены Вами.</p>

      <h2 class="h4 my-8">5. Оформление Заказа Товаров</h2>
      <p>5.1. Заказ Покупателя может быть оформлен по Телефону или путем заполнения электронной
        формы Заказа на Сайте.</p>
      <p>5.2. Данные, которые Вы предоставляете нам при направлении Заказа, должны быть
        достоверными и достаточными для его исполнения.</p>
      <p>5.3. В случае возникновения у Вас дополнительных вопросов, касающихся Товаров, Вы можете
        обратиться в нашу службу поддержки по Телефону или на Электронную почту.</p>
      <p>5.4. Вы может заказать только те Товары, которые есть у нас в наличии на момент поступления Заказа.</p>
      <p>5.5. При оформлении Заказа Вы можете самостоятельно определять состав наборов Товара,
        если такая техническая возможность предусмотрена для таких наборов Товаров на Сайте.</p>
      <p>5.6. Вы не можете изменить состав Заказа после его оформления.</p>
      <p>5.7. Вы предоставляете нам согласие на запись телефонных разговоров с Вами, а также на
        сохранение истории переписки, если такая информация нужна нам для исполнения обязанностей,
        предусмотренных настоящей Офертой, а также для оценки качества услуг.</p>
      <p>5.8. Если у Нас отсутствует необходимое количество или ассортимент заказанных Вами
        Товаров, то мы сообщим Вам об этом по телефону в течение 24 часов с момента получения Нами
        Заказа. В этом случае Вы вправе согласиться с заменой или отсутствием части позиций в Заказе,
        либо отменить весь Заказ полностью. Если мы не сможем до Вас дозвониться, то мы вправе
        аннулировать весь Заказ.</p>
      <p>5.9. Мы вправе приостанавливать прием Заказов Товаров, например, в случае нехватки
        курьеров, Товаров или в случае технических неполадок. Мы вправе ограничить или приостановить
        прием Заказов с доставкой в некоторые районы и регионы. Информацию о возобновлении приема
        Заказов Вы можете отслеживать на Сайте самостоятельно.</p>
      <p>5.10. Для размещения Заказа Вам необходимо заполнить все поля на странице оформления
        Заказа на Сайте и нажать на кнопку "Оформить" (иную подобную кнопку) для размещения Заказа.
        После этого на Вашу электронную почту будет направлено письмо с подтверждением того, что мы
        получили Ваш Заказ. После получения Заказа Мы позвоним Вам по предоставленному Вами
        телефону для подтверждения (уточнения) данных. После подтверждения Нами Заказа по
        телефону, Договор считается заключенным.</p>

      <h2 class="h4 my-8">6. Доставка Товаров</h2>
      <p>6.1. Товары, которые мы предлагаем на Сайте, доступны только на территории Российской
        Федерации. Услуга доставки предоставляется не на всей территории России. В случае
        невозможности осуществить доставку Заказа на Ваш адрес, мы уведомим Вас об этом.</p>
      <p>6.2. Вы можете самостоятельно забрать Товары в одном из Наших Магазинов. Товары также
        могут быть доставлены Курьерской службой по адресу, указанному Вами при оформлении Заказа,
        обычным и экспресс методами.</p>
      <p>6.3. Мы самостоятельно устанавливаем Условия доставки исходя из своих возможностей. Мы
        также вправе в одностороннем порядке менять Условия доставки без каких-либо дополнительных
        уведомлений. До размещения Заказа Вы должны ознакомиться с актуальными Условиям доставки
        на Сайте либо по Телефону.</p>
      <p>6.4. В некоторых из наших Магазинов нельзя забрать предварительно оформленный Заказ. Мы
        указываем перечень таких магазинов в Условиях доставки (п. 6.2.).</p>
      <p>6.5. Доставка Товаров по адресам, не входящим в зону доставки, не осуществляется. Адреса,
        по которым осуществляется доставка Товаров, Вы можете узнать на Сайте или по Телефону.</p>
      <p>6.6. Сроки доставки, указанные на Сайте, либо сообщенные по Телефону (Электронной почте),
        являются ориентировочными. Реальные сроки доставки могут отклоняться в сторону уменьшения
        или увеличения по причинам, не зависящим от нас (например: загруженность дорог, количество
        Заказов на указанное время, погодные условия, форс-мажорные обстоятельства). При наличии
        вышеуказанных обстоятельств Продавец не считается просрочившим доставку.</p>
      <p>6.7. Доставка осуществляется при условии Заказа Вами Товаров на минимально необходимую
        сумму. Минимальная сумма Заказа определяется Нами в одностороннем порядке и указывается
        на Сайте в Условиях доставки.</p>
      <p>6.8. Если Курьерская служба по прибытии к Вашему адресу доставки не может передать Вам
        Товаров по причинам, не связанным с Курьерской службой (например, Покупатель в течение 10
        минут не отвечает на телефонный вызов или не открывает дверь Курьерской службе), то
        Покупатель считается отказавшимся от исполнения договора, заключенного на основании
        настоящей Оферты, а его Заказ аннулируется. В данном случае мы вправе потребовать
        возмещения Вами убытков в размере стоимости несостоявшейся доставки Курьерской службой.</p>
      <p>6.9. Доставка считается осуществленной, а Заказ - доставленным в момент подписания
        документов о получении Заказа по согласованному адресу доставки.</p>
      <p>6.10. Подписывая накладную (акт приема-передачи) Курьерской службы, Вы подтверждаете
        получение всех Товаров в правильном количестве и ассортименте, что у Товаров нет внешних
        недостатков, и Вы не имеете претензий в отношении данного Заказа и Товаров.</p>
      <p>6.11. В случае если Вы передаете третьему лицу (Вашему другу, родственнику и т. д.) номер
        Заказа, и такое лицо предъявляет данный номер Курьерской службе и (или) персоналу Магазина
        вместе с иными необходимыми документами, это расценивается нами как Ваша передача такому
        лицу всех полномочий по получению (и оплате, если это применимо) Вашего Заказа.</p>

      <h2 class="h4 my-8">7. Оплата Товаров</h2>
      <p>7.1. Цены на Товары определяются Нами в одностороннем порядке и указываются на Сайте.</p>
      <p>7.2. Оплата Товаров Покупателем производится в рублях Российской Федерации одним из
        следующих способов:</p>
      <ol class="my-4">
        <li>
          <p>7.2.1. Оплата банковской картой Visa, Master Card, "Мир", SberPay или Apple Pay/Google Pay (при
            наличии технической возможности) на Сайте. Для этого после оформления Заказа Покупатель
            нажимает кнопку "Оформить Заказ". Проведение операций оплаты банковскими картами
            осуществляется с применением 3DSecure технологий защиты. Порядок проведения оплаты
            следующий:</p>
          <ol class="terms__text ul-letters my-4">
            <li>Продавец перенаправляет Покупателя на платёжную страницу, на которой находится
              форма для указания реквизитов карты. Покупатель вводит следующую информацию: номер карты,
              дату окончания срока действия карты, значения CVC или CVV, специальный пароль для
              подтверждения оплаты.
            </li>
            <li>Продавец обрабатывает Заказ и передаёт банку информацию о нём.</li>
            <li>Информация об операции передаётся в банк.</li>
            <li>Если оплата одобрена банком, Продавец и Покупатель получают подтверждение. Если
              банк отклонил операцию по оплате, эта информация будет передана Покупателю и Продавцу.
            </li>
            <li>Обработка одобренной банком операции по оплате Заказа осуществляется автоматически не
              позднее следующего рабочего дня после дня совершения операции.
            </li>
            <li>После получения оплаты Продавец собирает Заказ и передаёт его выбранным Вами
              способом.
            </li>
          </ol>
        </li>
        <li>
          <p>7.2.2. Оплата наличными денежными средствами или банковской картой при получении Заказа в
            Магазине.</p>
        </li>
        <li>
          <p>7.2.3. Оплата наличными денежными средствами по факту получения Товаров от Курьерской
            службы. Курьерская служба вправе проверить подлинность наличных денежных средств. Максимальная сумма Заказа,
            при которой возможна оплата наличными денежными средствами,
            определяется Нами в одностороннем порядке и указывается на Сайте. На дату публикации
            настоящей Оферты максимальная сумма при оплате наличными денежными средствами
            составляет 100 000 ₽.</p>
        </li>
        <li>
          <p>7.2.4. Оплата банковской картой Visa, Master Card, "Мир", SberPay или Apple Pay/Google Pay (при
            наличии технической возможности) по факту получения Товаров, при условии оснащения
            Курьерской службы мобильными POS-терминалами.</p>
        </li>
      </ol>
      <p>7.3. Операции по банковским картам совершаются исключительно держателем карты либо
        уполномоченным им лицом. Проведение платежей по банковским картам осуществляется в
        строгом соответствии с требованиями платежных систем Visa Int., MasterCard Europe Sprl., Мир.</p>
      <p>7.4. Способ оплаты Товаров, доступный Вам на момент оформления Заказа, определяется
        нами из числа поименованных выше вариантов с учетом технических, временных, материальных
        и/или иных факторов.</p>
      <p>7.5. Выдача либо направление Покупателю кассового чека осуществляется следующим
        образом:</p>
      <ol class="my-4">
        <li>
          <p>7.5.1. В электронном виде на телефон или на электронную почту Покупателя, указанные им при
            оформлении Заказа Товаров, если Заказ был сделан через Сайт или по Телефону, а
            соответствующие контактные данные были предоставлены Покупателем;</p>
        </li>
        <li>
          <p>7.5.2. В бумажном виде:</p>
          <ol class="ul-letters mt-4">
            <li>
              <p>если Покупатель оформляет Заказ по Телефону с номера, по которому невозможно
                отправить Покупателю чек в электронном виде;</p>
            </li>
            <li>
              <p>если Покупатель, разместив Заказ, не оставил контактные данные, по которым возможно
                отправить ему чек в электронном виде.</p>
            </li>
          </ol>
        </li>
      </ol>
      <p>7.6. Вы вправе в любое время обратиться к нам с просьбой об отмене транзакции при оплате
        Заказа банковской картой. Решение о возврате денежных средств будет принято нами с учетом
        законодательства и всех обстоятельств.</p>


      <h2 class="h4 my-8">8. Возврат и обмен Товаров</h2>
      <p>8.1. Возврат и обмен продуктов питания надлежащего качества не допускается.</p>
      <p>8.2. Мы не производим возврат и обмен Товаров надлежащего качества, входящих в перечень,
        установленный Постановлением Правительства РФ от 31.12.2020 N 2463.</p>
      <p>8.3. Мы обязуемся передать Вам Товары, которые полностью соответствуют Вашему Заказу, а
        качество которых соответствует информации, представленной Вам на Сайте (по Телефону), а
        также информации, доведенной до Вашего сведения при передаче Товаров (на этикетке или на
        вкладыше).</p>
      <p>8.4. При получении Товаров Вы должны проверить соответствие полученных Товаров своему
        Заказу, включая комплектность и отсутствие претензий к внешнему виду в момент принятия
        Товаров.</p>
      <p>8.5. В случае получения некачественных Товаров, либо в случае несоответствия полученных
        Товаров Заказу, Вы вправе потребовать замены таких Товаров Товарами надлежащего качества, а
        также возврата уплаченных за Товары денежных средств.</p>
      <p>8.6. Будучи потребителем, по закону Вы вправе расторгнуть Договор (за исключением случаев,
        когда предметом Договора являются такие товары, в отношении которых Договор не может быть
        расторгнут в соответствии с п.п. 8.1., 8.2.) в течение 7 календарных дней после получения Вами
        Товаров. В таком случае Вы вправе получить возврат суммы денежных средств, уплаченной за
        такой Товар.</p>
      <p>8.7. Ваше право на расторжение Договора действует только в отношении Товара,
        возвращенного в том же состоянии, в котором он был получен Вами, при этом Вы обязаны также
        вернуть все относящиеся к Товару документы (гарантийный талон, инструкции), принадлежности,
        комплектующие, индивидуальную упаковку Товара.</p>
      <p>8.8. Мы вправе отказать в возврате Товара и уплаченных Вами денежных средств, если
        возвращаемый Товар находится в состоянии, отличном от того, в котором он был Вами получен
        (например, отсутствуют индивидуальная упаковка, бирки, ярлыки, пломбы, комплектующие и
        принадлежности Товара, утрачен его товарный вид).</p>
      <p>8.9. При возврате Товара Вы должны предоставить надлежащим образом заполненную форму
        на возврат, размещенную на Сайте. Форма может быть выслана на Ваш адрес электронной почты
        по Вашему запросу, направленному по Телефону или по Электронной почте.</p>
      <p>8.10. Вы вправе вернуть Товар в любой Магазин, за исключением Магазинов, расположенных в
        аэропортах. Для этого Вам необходимо посетить Магазин и предъявить вместе с товаром
        надлежащим образом заполненную Форму возврата (далее - "Запрос на возврат товара"),
        товарный чек, копию паспорта, накладную курьера или акт приема-передачи (в случае
        первоначальной доставки Курьерской службой), фискальный чек или выписку с банковского счета,
        подтверждающие оплату Товара.</p>
      <p>8.11. Вы вправе вернуть Товар посредством Курьерской службы. При возврате посредством
        Курьерской службы, Вам следует связаться с Нами по Телефону или по Электронной почте для
        организации обратной доставки. Вы должны направить продукцию в той же упаковке, в которой
        продукция была получена Вами, следуя указаниям, полученным от Нас по Телефону или по
        Электронной почте. К возвращаемой продукции необходимо приложить надлежащим образом
        заполненную Форму возврата, товарный чек, копию паспорта, накладную курьера или акт приема-
        передачи (в случае первоначальной доставки Курьерской службой), фискальный чек или выписку с
        банковского счета, подтверждающие оплату Товара.</p>
      <p>8.12. Конкретные документы, подлежащие предоставлению Вами при возврате, зависят от
        способа оплаты, использованного Вами при оплате Заказа, и места доставки Товара.</p>
      <p>8.13. Мы обязуемся проверить возвращаемый Вами Товар и уведомить Вас о принятом решении
        в течение 10 дней с даты получения возвращенного Вами Товара.</p>
      <p>8.14. Возврат денежных средств осуществляется способом, выбранным Вами для
        осуществления оплаты, за исключением случаев, когда иное предусмотрено законодательством.</p>
      <p>8.15. При возвращении уплаченной суммы Мы используем способ, использовавшийся Вами для
        первоначальной оплаты Товара. Денежные средства возвращаются нами одним из следующих
        способов:</p>
      <ol class="ul-letters my-4">
        <li>
          <p>Выдача наличных денежных средств в Магазине;</p>
        </li>
        <li>
          <p>Возврат на банковскую карту, с использованием которой был приобретен Товар;</p>
        </li>
      </ol>
      <p>8.16. Если по Вашему мнению Товар не соответствует условиям Договора на момент доставки,
        Вам следует незамедлительно связаться с нами по Электронной почте и сообщить нам всю
        информацию о Товаре и его недостатках.</p>
      <p>8.17. Возврат денежных средств за некачественный Товар осуществляется в порядке,
        предусмотренном п.п. 8.9. - 8.15. настоящей Оферты.</p>
      <p>8.18. В случае наличия брака сумма, уплаченная Вами за Товар ненадлежащего качества,
        подлежит возврату в полном объеме, включая возмещение расходов на доставку и любых других
        обоснованных затрат, понесенных Вами при возврате Товаров.</p>


      <h2 class="h4 my-8">9. Форс-мажор</h2>
      <p>9.1. Любая из Сторон освобождается от ответственности за полное или частичное
        неисполнение своих обязательств по настоящей Оферте, если это неисполнение было вызвано
        обстоятельствами непреодолимой силы. Обстоятельства непреодолимой силы означают
        чрезвычайные события и обстоятельства, которые Стороны не могли ни предвидеть, ни
        предотвратить разумными средствами. Такие чрезвычайные события или обстоятельства
        включают в себя, в частности: забастовки, наводнения, пожары, землетрясения, эпидемии и иные
        стихийные бедствия, войны, военные действия и так далее.</p>

      <h2 class="h4 my-8">10. Ответственность Сторон публичной Оферты</h2>
      <p>10.1. Мы не несет ответственности за ущерб, причиненный Вам вследствие ненадлежащего
        использования Вами Товаров.</p>
      <p>10.2. Мы не отвечаем за убытки Покупателя, возникшие в результате предоставления Вами
        недостоверных данных при оформлении Заказа.</p>
      <p>10.3. Мы не несем какую-либо ответственность перед Вами или каким-либо третьим лицом по
        причине удаления какой-либо информации (в т.ч., Товара) с Сайта, удаления или редактирования
        каких-либо материалов или наполнения Сайта, отказа от обработки или принятия Заказа.</p>
      <p>10.4. При любых обстоятельствах ответственность Продавца в соответствии со статьей 15
        Гражданского кодекса России ограничена суммой в размере 10 000 (десять тысяч) рублей и
        возлагается на него при наличии в его действиях вины.</p>

      <h2 class="h4 my-8">11. Условия использования Сайта</h2>
      <p>11.1. Вы вправе использовать Сайт только в законных целях и способами, не нарушающими
        права третьих лиц.</p>
      <p>11.2. Вы не вправе размещать спекулятивные, ложные Заказы или Заказы с целью совершения
        мошенничества. В случае наличия у нас оснований полагать, что Вы разметили такой Заказ, мы
        вправе отменить такой Заказ в одностороннем порядке и (или) уведомить об этом
        правоохранительные органы.</p>
      <p>11.3. Вы самостоятельно несете ответственность перед третьими лицами за свои действия,
        связанные с использованием сервисов Сайта, в том числе, если такие действия приведут к
        нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства РФ
        при использовании сервисов Сайта.</p>
      <p>11.4. Мы не несем ответственности за вред, причиненный Вашей технике в случае, если это
        произошло в результате перехода по гипертекстуальным ссылкам, размещенным на Сайте.</p>
      <p>11.5.Мы вправе произвести приостановление оказания услуг пользования сервисами Сайта,
        либо отказать Вам в возможности использования отдельных сервисов Сайта, в случае нарушения
        Вами положений настоящей Оферты.</p>
      <p>11.6. Все объекты, размещенные на Сайте, в том числе элементы дизайна, текст, графические
        изображения, иллюстрации, фотографии, видео и иные охраняемые законом материалы (далее -
        содержание сервисов Сайта) являются объектами исключительных прав Продавца.</p>
      <p>11.7.Никакие элементы содержания сервисов Сайта, не могут быть использованы Вами без
        предварительного письменного разрешения Продавца. Под использованием подразумеваются, в
        том числе: воспроизведение, копирование, переработка, распространение на любой основе, и т.д.
        Исключение составляют случаи, прямо предусмотренные законодательством РФ.</p>


    </v-container>
  </section>
</template>

<script>

export default {
  name: "terms",

}
</script>

<style lang="scss">
.terms {
  /*margin-top: 128px;
  width: 100%;
  height: calc(100% - 128px);

  &__wrapper {
    width: 100%;
  }

  &__list {
    list-style-type: decimal;
  }*/

  &__text {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 2px;
  }

  /*
  h5, .policy__text {
    color: $black-200;
  }*/
}
</style>